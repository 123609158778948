<template>
  <div v-if="items.length > 0">
    <v-alert
      class="ma-0 rounded-t-0"
      type="info"
      text
      v-if="settings.assessorNow"
      :prominent="!$vuetify.breakpoint.xs"
    >
      Zweitbeurteilungen können bis
      <DateValue :value="settings.assessorEndDate" long />,
      {{ settings.studentTitleEndTime }} eingetragen werden
    </v-alert>
    <v-alert
      class="ma-0"
      type="info"
      text
      v-if="settings.coachTitleNow"
      :prominent="!$vuetify.breakpoint.xs"
    >
      Du kannst die Titel der von dir betreuten Arbeiten bis am
      <DateValue :value="settings.coachTitleEndDate" long />,
      {{ settings.studentTitleEndTime }} Uhr anpassen
    </v-alert>
    <v-card flat class="rounded-t-0" :loading="loading">
      <v-list subheader>
        <template v-if="itemsCoach.length > 0">
          <v-subheader>Betreuung</v-subheader>
          <v-tooltip top v-for="item in itemsCoach" :key="'ma' + item.id">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <PersonItem :value="item.student" />
              </div>
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>
        </template>
        <template v-if="itemsAssessor.length > 0">
          <v-subheader>Zweitbeurteilung</v-subheader>
          <v-list-item class="flex-wrap">
            <v-tooltip top v-for="item in itemsAssessor" :key="'ma' + item.id">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <PersonItem small :value="item.student" />
                </div>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </v-list-item>
        </template>
      </v-list>
      <v-divider />
      <v-card-actions>
        <v-btn text block small :to="{ name: 'Thesis' }"
          >zu den Maturaarbeiten</v-btn
        ></v-card-actions
      >
    </v-card>
  </div>
</template>
<script>
import DateValue from "common/components/DateValue.vue";
import PersonItem from "@/components/PersonItem.vue";
export default {
  name: "ThesisModule",
  components: { DateValue, PersonItem },
  data() {
    return {
      loading: false,
      items: [],
      settings: {},
    };
  },
  computed: {
    itemsCoach() {
      return this.items.filter(
        (item) =>
          (item.firstCoach && this.$_isPerson(item.firstCoach)) ||
          (item.secondCoach && this.$_isPerson(item.secondCoach))
      );
    },
    itemsAssessor() {
      return this.items.filter(
        (item) => item.assessor && this.$_isPerson(item.assessor)
      );
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.settings = await this.apiList({
        resource: "thesis/settings",
      });

      this.items = await this.apiList({
        resource: "thesis/thesis",
        query: `teacher=${this.$_profilePerson.id}`,
      });

      this.loading = false;
      if (this.items.length > 0) {
        this.$emit("loaded");
      }
    },
  },
  async created() {
    this.fetchData();
  },
};
</script>
